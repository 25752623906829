import Vue from 'vue'
import VueRouter from 'vue-router'
import userService from '../services/user.service'
Vue.use(VueRouter)

const routes = [
  {
    path: '/pago',
    name: 'pago_validate',
    component: () => import('../components/pasarelas/finalizando'),
    meta :{
      log:false,
      admin : false,
      paciente : false,
      coordinacion : false,
      aten1 : false,
      aten2 : false,
      asis1 : false,
      asis2 : false,
      asis3 : false,
      doctor : false,
      auth: false,
    }
  },

    
    {
      path: '/imprimir',
      name: 'imprimir',
      component: () => import('../components/pacientes/imprimir'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },

    {
      path: '/',
      name: 'Home',
      component: () => import('../views/Home'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    // {
    //   path: '/',
    //   name: 'Proximamente',
    //   component: () => import('../views/Proximamente'),
    //   meta :{
    //     log:false,
    //     admin : false,
    //     paciente : false,
    //     coordinacion : false,
    //     aten1 : false,
    //     aten2 : false,
    //     asis1 : false,
    //     asis2 : false,
    //     asis3 : false,
    //     doctor : false,
    //     auth: false,
    //   }
    // },

    {
      path: '/administrador/doctor',
      name: 'maindashboard_doctor',
      component: () => import('../views/DashDoctor'),
      meta :{
        log:true,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : true,
        auth: false,
      }
    },
    {
      path: '/administrador/coordinacion',
      name: 'maindashboard_coordinacion',
      component: () => import('../views/DashCoordinacion'),
      meta :{
        log:true,
        admin : false,
        paciente : false,
        coordinacion : true,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/administrador/atencion-inicial-1',
      name: 'maindashboard_aten1',
      component: () => import('../views/DashAten1'),
      meta :{
        log:true,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : true,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/administrador/atencion-inicial-2',
      name: 'maindashboard_aten2',
      component: () => import('../views/DashAten2'),
      meta :{
        log:true,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : true,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/administrador/asistente-medico-1',
      name: 'maindashboard_asis1',
      component: () => import('../views/DashAsis1'),
      meta :{
        log:true,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : true,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/administrador/asistente-medico-2',
      name: 'maindashboard_asis2',
      component: () => import('../views/DashAsis2'),
      meta :{
        log:true,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : true,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/administrador/asistente-medico-3',
      name: 'maindashboard_asis3',
      component: () => import('../views/DashAsis3'),
      meta :{
        log:true,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : true,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/administrador/finanzas',
      name: 'finanzas',
      component: () => import('../views/DashFinanzas'),
      meta :{
        log:true,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
        finanzas: true,
      }
    },
    {
      path: '/administrador/paciente',
      name: 'maindashboard_paciente',
      component: () => import('../views/DashPaciente'),
      meta :{
        log:true,
        admin : false,
        paciente : true,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/servicios',
      name: 'Servicios',
      component: () => import('../views/Servicios'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/servicios/balon-gastrico',
      name: 'Balon',
      component: () => import('../components/servicios/Balon.vue'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/services/gastric-balloon-texas',
      name: 'balloon',
      component: () => import('../components/servicios/BalonEngTX.vue'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/services/gastric-balloon-new-york',
      name: 'balloon',
      component: () => import('../components/servicios/BalonEngNY.vue'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/services/gastric-balloon-miami',
      name: 'balloon',
      component: () => import('../components/servicios/BalonEngMI.vue'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/services/gastric-balloon-california',
      name: 'balloon',
      component: () => import('../components/servicios/BalonEngCA.vue'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/services/gastric-balloon-arizona',
      name: 'balloon',
      component: () => import('../components/servicios/BalonEngAZ.vue'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/servicios/endosleeve',
      name: 'Endosleeve',
      component: () => import('../components/servicios/Endosleeve.vue'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/servicios/bypass-gastrico',
      name: 'Bypass',
      component: () => import('../components/servicios/Bypass.vue'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/servicios/tore',
      name: 'Tore',
      component: () => import('../components/servicios/Tore.vue'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/servicios/re-manga',
      name: 'ReManga',
      component: () => import('../components/servicios/ReManga.vue'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/servicios/manga-gastrica',
      name: 'Manga',
      component: () => import('../components/servicios/Manga.vue'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/terminos-y-condiciones',
      name: 'Politicas',
      component: () => import('../views/Terminos'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/aviso-de-privacidad',
      name: 'Aviso',
      component: () => import('../views/Aviso'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/nuestro-equipo',
      name: 'NuestroEquipo',
      component: () => import('../views/NuestroEquipo'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/cuestionario',
      name: 'cuestionario',
      component: () => import('../views/cuestionario/cuestionario.vue'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/Login'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
    {
      path: '/contacto',
      name: 'Contacto',
      component: () => import('../views/ContactoSolo'),
      meta :{
        log:false,
        admin : false,
        paciente : false,
        coordinacion : false,
        aten1 : false,
        aten2 : false,
        asis1 : false,
        asis2 : false,
        asis3 : false,
        doctor : false,
        auth: false,
      }
    },
  {
    path: '/auth',
    name: 'autentificacion',
    component: () => import('../views/Autentificacion'),
    meta :{
      log:true,
      admin : true,
      paciente : false,
      coordinacion : false,
      aten1 : false,
      aten2 : false,
      asis1 : false,
      asis2 : false,
      asis3 : false,
      doctor : false,
      auth: false
    }
  },

  {
    path: '/administrador',
    name: 'Dashboard',
    component: () => import('../views/Dashboard'),
    meta :{
      log:true,
      admin : true,
      paciente : false,
      coordinacion : false,
      aten1 : false,
      aten2 : false,
      asis1 : false,
      asis2 : false,
      asis3 : false,
      doctor : false,
      auth: true, 
    }
  },


  {
    path: '*',
    name: '404',
    component: () => import('../views/Error404'),
    meta :{
     log:false,
     admin : false,
     paciente : false,
     coordinacion : false,
     aten1 : false,
     aten2 : false,
     asis1 : false,
     asis2 : false,
     asis3 : false,
     doctor : false,
     auth: false
    }
  },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach( async (to, from, next) => {

  let meta_admin = to.matched.some(record=>record.meta.admin);
  let meta_paciente = to.matched.some(record=>record.meta.paciente);
  let meta_coordinacion = to.matched.some(record=>record.meta.coordinacion);
  let meta_doctor = to.matched.some(record=>record.meta.doctor);
  let meta_log = to.matched.some(record=>record.meta.log);
  let meta_auth = to.matched.some(record=>record.meta.auth);
  let meta_aten1 = to.matched.some(record=>record.meta.aten1);
  let meta_aten2 = to.matched.some(record=>record.meta.aten2);
  let meta_asis1 = to.matched.some(record=>record.meta.asis1);
  let meta_asis2 = to.matched.some(record=>record.meta.asis2);
  let meta_asis3 = to.matched.some(record=>record.meta.asis3);
  let meta_finanzas = to.matched.some(record=>record.meta.finanzas);

  
  if(!meta_log) return next();


  let tokenexp = await userService.tokenexp()
  let check =false //comprobar si esta logeado
  let admin =false;
  let paciente = false;
  let doctor = false;
  let coordinacion = false;
  let aten1 = false;
  let aten2 = false;
  let asis1 = false;
  let asis2 = false;
  let asis3 = false;
  let finanzas = false;
  let type =''


  


  let response = await userService.getData()

 


  if(response.status=='success'){ //comprobar que sea un login valido.
    check =true;
    let isAdmin = userService.isAdmin(response.result);
    admin = isAdmin.admin
    type = isAdmin.type

    let isPat = userService.isPat(response.result);
    paciente = isPat.value
    let isDoc = userService.isDoc(response.result);
    doctor = isDoc.value
    let isCoor = userService.isCoor(response.result);
    coordinacion = isCoor.value
    let isAten1 = userService.isAten1(response.result);
    aten1 = isAten1.value
    let isAten2 = userService.isAten2(response.result);
    aten2 = isAten2.value
    let isAsis1 = userService.isAsis1(response.result);
    asis1 = isAsis1.value
    let isAsis2= userService.isAsis2(response.result);
    asis2 = isAsis2.value
    let isAsis3  = userService.isAsis3(response.result);
    asis3 = isAsis3.value
    let isFinanzas  = userService.isFinanzas(response.result);
    finanzas = isFinanzas.value
  }

  let ath = await userService.getAth();




  if(meta_admin){
        if(admin){
        
          if(meta_auth){
            if(ath){
              next();
            }else{
              localStorage.removeItem('tkn')
              localStorage.removeItem('ath')
              next('/login')
            }
          }else{ //...proximamente rutas que no requieran autentificacion
            next()
          }
          
        }else{
          localStorage.removeItem('tkn')
          localStorage.removeItem('ath')
          next('/')
        }
  }else if(meta_paciente){
    if(paciente){
        
      if(meta_auth){
        if(ath){
          next();
        }else{
          localStorage.removeItem('tkn')
          localStorage.removeItem('ath')
          next('/login')
        }
      }else{ //...proximamente rutas que no requieran autentificacion
        next()
      }
      
    }else{
      localStorage.removeItem('tkn')
      localStorage.removeItem('ath')
      if(to?.fullPath){
        localStorage.setItem('fullPath', to.fullPath)
      }
      next('/login')
    }

  }else if(meta_coordinacion){
    if(coordinacion){
        
      if(meta_auth){
        if(ath){
          next();
        }else{
          localStorage.removeItem('tkn')
          localStorage.removeItem('ath')
          next('/login')
        }
      }else{ //...proximamente rutas que no requieran autentificacion
        next()
      }
      
    }else{
      localStorage.removeItem('tkn')
      localStorage.removeItem('ath')
      next('/')
    }

  }else if(meta_aten1){
    if(aten1){
        
      if(meta_auth){
        if(ath){
          next();
        }else{
          localStorage.removeItem('tkn')
          localStorage.removeItem('ath')
          next('/login')
        }
      }else{ //...proximamente rutas que no requieran autentificacion
        next()
      }
      
    }else{
      localStorage.removeItem('tkn')
      localStorage.removeItem('ath')
      next('/')
    }

  }else if(meta_aten2){
    if(aten2){
        
      if(meta_auth){
        if(ath){
          next();
        }else{
          localStorage.removeItem('tkn')
          localStorage.removeItem('ath')
          next('/login')
        }
      }else{ //...proximamente rutas que no requieran autentificacion
        next()
      }
      
    }else{
      localStorage.removeItem('tkn')
      localStorage.removeItem('ath')
      next('/')
    }

  }else if(meta_asis1){
    if(asis1){
        
      if(meta_auth){
        if(ath){
          next();
        }else{
          localStorage.removeItem('tkn')
          localStorage.removeItem('ath')
          next('/login')
        }
      }else{ //...proximamente rutas que no requieran autentificacion
        next()
      }
      
    }else{
      localStorage.removeItem('tkn')
      localStorage.removeItem('ath')
      next('/')
    }

  }else if(meta_asis2){
    if(asis2){
        
      if(meta_auth){
        if(ath){
          next();
        }else{
          localStorage.removeItem('tkn')
          localStorage.removeItem('ath')
          next('/login')
        }
      }else{ //...proximamente rutas que no requieran autentificacion
        next()
      }
      
    }else{
      localStorage.removeItem('tkn')
      localStorage.removeItem('ath')
      next('/')
    }

  }else if(meta_asis3){
    if(asis3){
        
      if(meta_auth){
        if(ath){
          next();
        }else{
          localStorage.removeItem('tkn')
          localStorage.removeItem('ath')
          next('/login')
        }
      }else{ //...proximamente rutas que no requieran autentificacion
        next()
      }
      
    }else{
      localStorage.removeItem('tkn')
      localStorage.removeItem('ath')
      next('/')
    }

  }else if(meta_finanzas){
    if(finanzas){
        
      if(meta_auth){
        if(ath){
          next();
        }else{
          localStorage.removeItem('tkn')
          localStorage.removeItem('ath')
          next('/login')
        }
      }else{ //...proximamente rutas que no requieran autentificacion
        next()
      }
      
    }else{
      localStorage.removeItem('tkn')
      localStorage.removeItem('ath')
      next('/')
    }

  }else if(meta_doctor){
    if(doctor){
        
      if(meta_auth){
        if(ath){
          next();
        }else{
          localStorage.removeItem('tkn')
          localStorage.removeItem('ath')
          next('/login')
        }
      }else{ //...proximamente rutas que no requieran autentificacion
        next()
      }
      
    }else{
      localStorage.removeItem('tkn')
      localStorage.removeItem('ath')
      next('/')
    }

  }else{
    next()
  }

 
});




export default router

